import React from 'react';
import { graphql } from 'gatsby';
import Img from 'react-image';
import Spinner from 'react-spinkit';

const InfoTag = p => <div className="info-tag">{p.children}</div>;

export const ProjectPageTemplate = ({ title, transition }) => (
  <section style={transition && transition.style} className="section">
    <div className="container">
      <div className="content">
        <h1 className="has-text-weight-bold thin-title">{title}</h1>
        <p className="thin-title">Works and various apps</p>
      </div>

      <hr />

      <section className="projects-container">
        <div className="projects-items">
          <div className="card">
            <figure>
              <Img
                src="/img/projassets/poemucard.png"
                loader={<Spinner name="folding-cube" />}
                alt="Poemu"
              />
            </figure>

            <figcaption>
              <span>
                Poemu |&nbsp;
                <a href="https://expo.io/@satiewaltz/Poemu">Demo</a>
              </span>

              <p>
                A React Native app about sharing anonymous poems. Using
                MongoDB for posts and Amazon S3 for file uploads.
              </p>
              <div className="info-tag-row">
                <InfoTag>React Native</InfoTag>
                <InfoTag>Expo</InfoTag>
                <InfoTag>Node.js</InfoTag>
                <InfoTag>MongoDB</InfoTag>
              </div>
            </figcaption>
          </div>

          <div className="card">
            <figure>
              <Img
                src="/img/projassets/ebooks.png"
                loader={<Spinner name="folding-cube" />}
                alt="eBooks"
              />
            </figure>

            <figcaption>
              <span>
                Sesame eBooks |&nbsp;
                <a href="http://library.sesamestreetjapan.org/">Demo</a>
              </span>

              <p>
                A collection of animated stories made with React and
                Anime.js. Based off an animation series to teach financial
                empowerment to children.
              </p>
              <div className="info-tag-row">
                <InfoTag>React</InfoTag>
                <InfoTag>Anime.js</InfoTag>
                <InfoTag>Node.js</InfoTag>
                <InfoTag>Redux</InfoTag>
                <InfoTag>React Router</InfoTag>
              </div>
            </figcaption>
          </div>

          <div className="card">
            <figure>
              <Img
                src="/img/projassets/reactionary.png"
                loader={<Spinner name="folding-cube" />}
                alt="Reactionary"
              />
            </figure>

            <figcaption>
              <span>
                Reactionary |&nbsp;
                <a href="https://github.com/satiewaltz/reactionary">
                  GitHub
                </a>
                {/* &nbsp;&&nbsp;
                <a href="https://api.theweb.rocks">Demo</a> */}
              </span>

              <p>
                A serverless RESTful API built for accessing Mark Erikson's
                react-redux-links collection.
              </p>
              <div className="info-tag-row">
                <InfoTag>Serverless</InfoTag>
                <InfoTag>AWS Lambda</InfoTag>
                <InfoTag>Node.js</InfoTag>
                <InfoTag>Express</InfoTag>
              </div>
            </figcaption>
          </div>

          <div className="card">
            <figure>
              <Img
                src="/img/projassets/simplyreact.png"
                loader={<Spinner name="folding-cube" />}
                alt="Simply React"
              />
            </figure>

            <figcaption>
              <span>
                Simply React |&nbsp;
                <a href="https://github.com/satiewaltz/simply-react">
                  GitHub
                </a>
              </span>

              <p>
                A deadsimple-to-use React boilerplate with RHL3.{' '}
                <span role="img" aria-label="rocket">
                  🚀
                </span>
              </p>
              <div className="info-tag-row">
                <InfoTag>React</InfoTag>
                <InfoTag>Webpack</InfoTag>
                <InfoTag>Node.js</InfoTag>
                <InfoTag>Babel</InfoTag>
                <InfoTag>Jest</InfoTag>
              </div>
            </figcaption>
          </div>

          <div className="card">
            <figure>
              <Img
                src="/img/projassets/saway.gif"
                loader={<Spinner name="folding-cube" />}
                alt="Spirited Away"
              />
            </figure>

            <figcaption>
              Spirited Away
              <p>
                Spirited Away is a audio/visual VR project built with
                AFrame.
              </p>
              <div className="info-tag-row">
                <InfoTag>AFrame</InfoTag>
                <InfoTag>WebVR</InfoTag>
                <InfoTag>Web Audio</InfoTag>
              </div>
            </figcaption>
          </div>

          <div className="card">
            <figure>
              <Img
                src="/img/projassets/bbmt.png"
                loader={<Spinner name="folding-cube" />}
                alt="Broken Box Mime Theater"
              />
            </figure>

            <figcaption>
              <span>
                Broken Box Mime Theater |&nbsp;
                <a href="http://brokenboxmime.com/">Demo</a>
              </span>

              <p>Landing page created for Broken Box Mime Theater.</p>

              <div className="info-tag-row">
                <InfoTag>WordPress</InfoTag>
                <InfoTag>SASS</InfoTag>
                <InfoTag>JS</InfoTag>
              </div>
            </figcaption>
          </div>

          <div className="card">
            <figure>
              <Img
                src="/img/projassets/nynice.png"
                loader={<Spinner name="folding-cube" />}
                alt="NYnice"
              />
            </figure>
            <figcaption>
              <span>
                NICE Community Job Center |&nbsp;
                <a href="http://www.nynice.org/">Demo</a>
              </span>

              <p>
                Landing page built for New Immigrant Community Empowerment.
              </p>

              <div className="info-tag-row">
                <InfoTag>WordPress</InfoTag>
                <InfoTag>SASS</InfoTag>
                <InfoTag>PHP</InfoTag>
              </div>
            </figcaption>
          </div>

          <div className="card">
            <figure>
              <Img
                src="/img/projassets/ariva.png"
                loader={<Spinner name="folding-cube" />}
                alt="Ariva"
              />
            </figure>

            <figcaption>
              <span>
                Ariva |&nbsp;
                <a href="http://www.ariva.org/">Demo</a>
              </span>

              <p>Developed with others at 48in48nyc 2016.</p>
              <div className="info-tag-row">
                <InfoTag>WordPress</InfoTag>
                <InfoTag>SASS</InfoTag>
                <InfoTag>PHP</InfoTag>
              </div>
            </figcaption>
          </div>

          <div className="card">
            <figure>
              <Img
                src="/img/projassets/claude.gif"
                loader={<Spinner name="folding-cube" />}
                alt="Claude"
              />
            </figure>

            <figcaption>
              Claude
              <p>
                A site made for experimenting with jQuery UI. In spirit of
                Claude Monet.
              </p>
              <div className="info-tag-row">
                <InfoTag>jQuery</InfoTag>
              </div>
            </figcaption>
          </div>

          <div className="card">
            <figure>
              <Img
                src="/img/projassets/batswat.gif"
                loader={<Spinner name="folding-cube" />}
                alt="Batswat"
              />
            </figure>

            <figcaption>
              Batswat
              <p>
                An JS game where you fight an endless wave of bats by
                pressing on buttons. Uses the Gamepad Web API for
                controller support.
              </p>
              <div className="info-tag-row">
                <InfoTag>jQuery</InfoTag>
                <InfoTag>Gamepad API</InfoTag>
              </div>
            </figcaption>
          </div>

          <div className="card">
            <figure>
              <Img
                src="/img/projassets/memeify.png"
                loader={<Spinner name="folding-cube" />}
                alt="Memeify"
              />
            </figure>

            <figcaption>
              Memeify
              <p>
                A fun little Ruby on Rails app that allows you to upload an
                image and add a unibrow to it. ¯\_(ツ)_/¯
              </p>
              <div className="info-tag-row">
                <InfoTag>Rails</InfoTag>
                <InfoTag>Cloudinary</InfoTag>
              </div>
            </figcaption>
          </div>
        </div>
        <br />
        <br />
        <div className="content">
          <h1 className="has-text-weight-bold thin-title">Animations</h1>
        </div>

        <hr />

        <iframe
          height={497}
          style={{ width: '100%' }}
          scrolling="no"
          title="Reactiflux Q&A"
          src="https://codepen.io/satiewaltz/embed/jzewMK?height=497&theme-id=dark&default-tab=js,result"
          frameBorder="no"
          allowTransparency="true"
          allowFullScreen="true"
          loading="lazy"
        >
          See the Pen &lt;a
          href='https://codepen.io/satiewaltz/pen/jzewMK'&gt;Reactiflux
          Q&amp;amp;A&lt;/a&gt; by Dave Barthly (&lt;a
          href='https://codepen.io/satiewaltz'&gt;@satiewaltz&lt;/a&gt;) on
          &lt;a href='https://codepen.io'&gt;CodePen&lt;/a&gt;.
        </iframe>
      </section>
    </div>
  </section>
);

export default ({ data, transition }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <ProjectPageTemplate
      title={frontmatter.title}
      transition={transition}
    />
  );
};

export const projectPageQuery = graphql`
  query ProjectPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        title
      }
    }
  }
`;
